@font-face {
  font-family: "LEDLIGHT";
  src: local("LEDLIGHT"),
   url("./fonts/LEDLIGHT.otf") format("opentype");
  font-weight: lighter;
 }

 @font-face {
  font-family: "LAMACHINECOMPANY";
  src: local("LAMACHINECOMPANY"),
   url("./fonts/LAMACHINECOMPANY.ttf") format("truetype");
  font-weight: lighter;
 }

.pageContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px;
}

.psykoLogo {
  max-height: 80vh;
  max-width: 80vh;
}

.rosterRowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  background: rgba(0, 0, 0, 0.9);
  margin: 5px;
  border-radius: 10px;
  padding: 9px;
}

.rosterRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.rosterRowPic {
  height: 100px;
  border-radius: 10px;
}
.rosterRowText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  width: 100%;
}
.rosterRowNameText {
  color: green;
  font-family: "LEDLIGHT";
  font-size: 25pt;
  margin: 9px;
}
.rosterLastUpdatedText {
  color: black;
  font-family: "LAMACHINECOMPANY";
  font-size: 25pt;
  margin: 9px;
}
.rosterRowRankText {
  margin: 0px;
  color: green;
  font-size: 20pt;
  /* font-family: "Lucida Console", Monaco, monospace; */
  font-family: "LAMACHINECOMPANY";
}

@media (max-width: 900px) {
  .psykoLogo {
    max-height: 50vh;
    max-width: 50vh;
  }
  .rosterRowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    background: rgba(0, 0, 0, 0.9);
    margin: 5px;
    border-radius: 10px;
    padding: 9px;
  }
  .rosterRowPic {
    height: 70px;
    border-radius: 10px;
  }
  .rosterRowNameText {
    color: green;
    font-family: "LEDLIGHT";
    font-size: 17pt;
    margin: 9px;
  }
  .rosterLastUpdatedText {
    color: black;
    font-family: "LAMACHINECOMPANY";
    font-size: 17pt;
    margin: 9px;
  }
  .rosterRowRankText {
    margin: 0px;
    color: green;
    font-size: 14pt;
    /* font-family: "Lucida Console", Monaco, monospace; */
    font-family: "LAMACHINECOMPANY";
  }

}